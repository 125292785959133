import React, { useState } from 'react';
import { FaHome, FaInbox, FaMoneyBill, FaChartPie, FaBook, FaUser, FaSignOutAlt, FaPeopleCarry, FaPeopleArrows, FaFilePdf } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { FiCircle, FiFileText, FiHome, FiLogOut, FiUser, FiUserPlus, FiUsers, FiX } from 'react-icons/fi';
import '../pages/DashboardRoot/dashboard.css';
import { Flex } from '@mantine/core';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

const SideBarDark = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isBookkeepingOpen, setIsBookkeepingOpen] = useState(true);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);

  const toggleSidebar = () => setOpen(!open);
  const toggleBookkeeping = (e: any) => {
    e.stopPropagation();
    setIsBookkeepingOpen(!isBookkeepingOpen);
  };
  const toggleAccountSettings = () => setIsAccountSettingsOpen(!isAccountSettingsOpen);

  return (
    <div className="sidebarUser">
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        onClick={toggleSidebar}
        className=" nav inline-flex items-center p-2 mt-5 ms-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      {open && (
        <div
          className="fixed inset-0 z-20 bg-black sm:hidden bg-opacity-50 sidebarUser" 
          onClick={toggleSidebar}
        ></div>
      )}
      
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          open ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0 bg-gray-800`}
        aria-label="Sidebar"
      >
        <div className="h-full bg-gray-800">
          <div className='flex border-b justify-center items-center w-full h-20 p-2'>
            <a
              href="/accountants/dashboard/"
              className="flex items-center p-2 text-white rounded-lg hover:bg-gray-700 group"
            >
              <img src={logo} alt="Outss Logo" width={120} />
            </a>
          </div>
          
          <ul className="space-y-2 font-medium px-4 pt-6" onClick={toggleSidebar}>
            <li className="dashboard-menu">
              <Link
                to="/accountants/dashboard"
                className={`flex items-center p-2 text-white rounded-lg hover:bg-gray-700 ${
                  location.pathname === '/accountants/dashboard' ? 'bg-gray-700' : ''
                }`}
              >
                {location.pathname === "/accountants/dashboard" ? <FiHome className="w-6 h-6 text-purple-500" /> :
                <FiHome className="w-6 h-6" />}
                <span className="ml-3">Dashboard</span>
              </Link>
            </li>
            
            <li>
              <button
                onClick={toggleBookkeeping}
                className="flex items-center w-full p-2 text-white rounded-lg hover:bg-gray-700"
              >
                <FiFileText className="w-6 h-6" />
                <span className="flex-1 ml-3 text-left">Bookkeeping</span>
                {isBookkeepingOpen ? <RiArrowUpSLine className="w-6 h-6" /> : <RiArrowDownSLine className="w-6 h-6" />}
              </button>
              {isBookkeepingOpen && (
                <ul className="pl-4 mt-2 space-y-2">
                  {/* <li className="clients-menu">
                    <Link
                      to="/accountants/clients"
                      className={`flex items-center p-2 text-white rounded-lg hover:bg-gray-700 ${
                        location.pathname === '/accountants/clients' ? 'bg-gray-700' : ''
                      }`}
                    >
                      <FiUsers className="w-5 h-5" />
                      <span className="ml-3">My Clients</span>
                    </Link>
                  </li> */}
                  <li className="users-menu">
                    <Link
                      to="/accountants/users"
                      className={`flex items-center p-2 text-white rounded-lg hover:bg-gray-700 ${
                        location.pathname === '/accountants/users' ? 'bg-gray-700' : ''
                      }`}
                    >
                      {location.pathname === "/accountants/users" ? <FiUserPlus className="w-6 h-6 text-purple-500" /> :
                      <FiUserPlus className="w-5 h-5" />}
                      <span className="ml-3">Clients</span>
                    </Link>
                  </li>
                  {/* <li className="bankstatement-menu">
                    <Link
                      to="/accountants/bankstatement"
                      className={`flex items-center p-2 text-white rounded-lg hover:bg-gray-700 ${
                        location.pathname === '/accountants/bankstatement' ? 'bg-gray-700' : ''
                      }`}
                    >
                      <FaFilePdf className="w-5 h-5" />
                      <span className="ml-3">Bank Statements</span>
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>

            {/* <li>
              <button
                onClick={toggleAccountSettings}
                className="flex items-center w-full p-2 text-white rounded-lg hover:bg-gray-700"
              >
                <FiUser className="w-6 h-6" />
                <span className="flex-1 ml-3 text-left">Account Settings</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
              {isAccountSettingsOpen && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>
                    <Link
                      to="/accountants/myaccount"
                      className={`flex items-center p-2 text-white rounded-lg hover:bg-gray-700 ${
                        location.pathname === '/accountants/myaccount' ? 'bg-gray-700' : ''
                      }`}
                    >
                      <FiUser className="w-5 h-5" />
                      <span className="ml-3">My Account</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li> */}

            <li className="logout-menu">
              <Link
                to="/accountants/acclogout"
                className="flex items-center p-2 text-white rounded-lg hover:bg-gray-700"
              >
                {location.pathname === "/accountants/acclogout" ? <FiLogOut className="w-6 h-6 text-purple-500" /> :
                <FiLogOut className="w-6 h-6" />}
                <span className="ml-3">Log out</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SideBarDark;