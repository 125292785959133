import React, { useState } from "react";
import { DateRangePicker, StaticRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { enGB } from "date-fns/locale";
import { addDays, addMonths, endOfMonth } from "date-fns";
import { MdClose } from "react-icons/md";
import mixpanel from "mixpanel-browser";

type Props = {
  onDateChange: (dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => void;
  onClose: () => void;
  from: string;
  currentDateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
};

const CustomDatePicker: React.FC<Props> = ({ 
  onDateChange, 
  onClose, 
  from, 
  currentDateRange = {
    startDate: new Date(),
    endDate: new Date()
  } 
}) => {
  
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>(currentDateRange);

  const predefinedRanges: StaticRange[] = [
    {
      label: "This Month",
      range: () => {
        const currentDate = new Date(); // Use current date
        return {
          startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
          endDate: endOfMonth(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate?.getTime() === definedRange.startDate?.getTime() &&
          range.endDate?.getTime() === definedRange.endDate?.getTime()
        );
      },
    },    
    {
      label: "Last Month",
      range: () => {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1); // Move to the previous month
        return {
          startDate: new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1), // First day of the last month
          endDate: endOfMonth(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)), // Last day of the last month
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate?.getTime() === definedRange.startDate?.getTime() &&
          range.endDate?.getTime() === definedRange.endDate?.getTime()
        );
      },
    },
    {
      label: "This Year",
      range: () => {
        const currentYear = new Date().getFullYear(); // Get current year
        return {
          startDate: new Date(currentYear, 0, 1), // January 1st of the current year
          endDate: new Date(currentYear, 11, 31), // December 31st of the current year
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate?.getTime() === definedRange.startDate?.getTime() &&
          range.endDate?.getTime() === definedRange.endDate?.getTime()
        );
      },
    },
    {
      label: "Last Year",
      range: () => {
        const lastYear = new Date().getFullYear() - 1; // Get previous year
        return {
          startDate: new Date(lastYear, 0, 1), // January 1st of the previous year
          endDate: new Date(lastYear, 11, 31), // December 31st of the previous year
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate?.getTime() === definedRange.startDate?.getTime() &&
          range.endDate?.getTime() === definedRange.endDate?.getTime()
        );
      },
    },    
    {
      label: "Custom",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate?.getTime() === definedRange.startDate?.getTime() &&
          range.endDate?.getTime() === definedRange.endDate?.getTime()
        );
      },
    },
  ];

  // Function to detect the platform
  function detectPlatform() {
    var userAgent = navigator.userAgent || navigator.vendor ;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
        return "mobile";
    }
    return "web";
  }

  // Store the platform
  var platform = detectPlatform();

  console.log(mixpanel.track(`${from} Fillter Visited`,{
    email: localStorage.getItem("mailId"),
    platform: platform,
  }));
  
  return (
    <div className="fixed bg-gray-300 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg z-10 w-full md:w-auto border py-4 md:py-0">
        <div className="flex flex-col md:flex-col items-center">
          <div>
            <div className="flex justify-between items-center px-4 py-2 md:py-0">
              <p className="text-lg font-bold text-center p-2 md:p-4">
                Select Date Range
              </p>
              <MdClose className="text-2xl cursor-pointer" onClick={onClose} />
            </div>
            <hr className="border-t border-gray-200 my" />
            <DateRangePicker
              locale={enGB}
              maxDate={new Date()}
              ranges={[selectedDateRange]}
              onChange={(ranges) => {
                setSelectedDateRange({
                  ...selectedDateRange,
                  startDate: ranges.range1?.startDate,
                  endDate: ranges.range1?.endDate,
                });

                console.log(ranges);
              }}
              moveRangeOnFirstSelection={false}
              staticRanges={predefinedRanges}
              months={window.innerWidth < 768 ? 1 : 2}
              direction={window.innerWidth < 768 ? "vertical" : "horizontal"}
              rangeColors={["#69339C"]}
              inputRanges={[]}
            />
          </div>
          <div className="w-full md:w-full p-2 md:p-4 right-0 text-right">
            <div className="mt-2 md:mt-4 justify-center md:justify-end">
              <button
                className="mt-2 py-1 px-2 md:py-2 w-[48%] md:w-auto md:px-4 bg-white text-gray-600 border-primary rounded focus:outline-none border text-lg md:text-base mr-2 md:mr-4"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="mt-2 py-1 px-2 md:py-2 w-[48%] md:w-auto md:px-4 bg-primary text-white rounded hover:bg-primary focus:outline-none text-lg md:text-base"
                onClick={() => {
                  mixpanel.track(`${from} Fillter Visited`,{
                    email: localStorage.getItem("mailId"),
                    platform: platform,
                  });
                  onDateChange({
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                  });
                  onClose();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;