import React, { useState, useEffect } from 'react';
import { BsClock, BsCheckCircleFill, BsXLg } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import axios from 'axios';
import { getServerUrl } from '../../../../config/constants';

interface TransactionReport {
  id: number;
  transaction_date: string;
  transaction_name: string;
  transaction_category: string;
  message: string;
  status: 'pending' | 'processed';
}

const AccTransactionReports: React.FC = () => {
  const [reports, setReports] = useState<TransactionReport[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMessage, setSelectedMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userid');

      if (!userId) {
        throw new Error('User ID not found in URL');
      }

      const response = await axios.get<TransactionReport[]>(`${getServerUrl()}/transaction-reports-list/?userid=${userId}`);
      setReports(response.data);
      setLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      setLoading(false);
    }
  };

  const handleMarkAsCompleted = async (id: number) => {
    try {
      setLoading(true);
      await axios.patch(`${getServerUrl()}/transaction-reports/${id}/`, { status: 'processed' });
      await fetchReports();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(JSON.stringify(err.response.data));
      } else {
        setError(err instanceof Error ? err.message : 'Failed to update status');
      }
      setLoading(false);
    }
  };

  const truncateMessage = (message: string) => {
    return message.length > 20 ? `${message.substring(0, 20)}...` : message;
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setSelectedMessage(null);
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSelectedMessage(null);
      }
    };

    if (selectedMessage) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [selectedMessage]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <AiOutlineLoading3Quarters className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="rounded-md border border-red-200 bg-red-50 p-4">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">

      
      <div className="relative overflow-x-auto rounded-lg border border-gray-200 shadow">
        <table className="w-full text-left text-sm">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700">
            <tr>
              <th className="px-6 py-4 font-medium">Transaction Date</th>
              <th className="px-6 py-4 font-medium">Transaction Name</th>
              <th className="px-6 py-4 font-medium">Category</th>
              <th className="px-6 py-4 font-medium">Message</th>
              <th className="px-6 py-4 font-medium">Status</th>
              <th className="px-6 py-4 font-medium">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {reports.map((report) => (
              <tr key={report.id} className="bg-white hover:bg-gray-50">
                <td className="px-6 py-4">{report.transaction_date}</td>
                <td className="px-6 py-4">{report.transaction_name}</td>
                <td className="px-6 py-4">{report.transaction_category}</td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => setSelectedMessage(report.message)}
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    {truncateMessage(report.message)}
                  </button>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-2">
                    {report.status === 'processed' ? (
                      <BsCheckCircleFill className="h-4 w-4 text-green-500" />
                    ) : (
                      <BsClock className="h-4 w-4 text-yellow-500" />
                    )}
                    <span 
                      className={
                        report.status === 'processed' 
                          ? 'text-green-500' 
                          : 'text-yellow-500'
                      }
                    >
                      {report.status.charAt(0).toUpperCase() + report.status.slice(1)}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  {report.status === 'pending' && (
                    <button
                      onClick={() => handleMarkAsCompleted(report.id)}
                      className="rounded-md bg-blue-50 px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-100"
                    >
                      Mark as Completed
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedMessage && (
        <div 
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
          onClick={handleOverlayClick}
        >
          <div className="relative w-full max-w-lg rounded-lg bg-white shadow-xl">
            <div className="border-b border-gray-200 px-6 py-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">Message Details</h3>
                <button
                  onClick={() => setSelectedMessage(null)}
                  className="rounded-full p-1 hover:bg-gray-100"
                >
                  <BsXLg className="h-5 w-5 text-gray-500" />
                </button>
              </div>
            </div>
            
            <div className="max-h-[60vh] overflow-y-auto px-6 py-4">
              <p className="whitespace-pre-wrap text-sm text-gray-600">
                {selectedMessage}
              </p>
            </div>
            
            <div className="border-t border-gray-200 px-6 py-4">
              <button
                onClick={() => setSelectedMessage(null)}
                className="w-full rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccTransactionReports;