import React, { useEffect, useState, useCallback, useRef } from 'react';
import { StreamChat, Channel as StreamChannel, Event } from 'stream-chat';
import {
  Chat,
  Channel,
  // ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useChannelActionContext,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import "../StreamChatHome/chat-styles.css";
import { FaCommentAlt, FaThumbtack, FaUserTie, FaCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getApiBaseUrl } from '../../../config/constants';
import mixpanel from 'mixpanel-browser';
import api from '../../../services/api';
import { CustomReactionSelector, CustomReactionsList } from '../StreamChatHome/custom-reaction-components';



interface ChatData {
  token: string;
  api_key: string;
  accountant_id: string;
  accountant_name: string;
  user_id: string;
  user_name: string;
  channel_id: string;
}

const PinnedMessageList: React.FC = () => {
  const { channel } = useChannelStateContext();
  const { jumpToMessage } = useChannelActionContext();
  const pinnedMessages = channel.state.pinnedMessages;

  if (pinnedMessages.length === 0) return null;

  const handlePinnedMessageClick = (messageId: string) => {
    if (jumpToMessage) {
      jumpToMessage(messageId);
    }
  };

  return (
    <div className="pinned-messages bg-gray-100 p-4 mb-4 rounded-lg shadow-sm">
      <h3 className="text-sm font-semibold mb-2 flex items-center text-gray-700">
        <FaThumbtack className="mr-2" /> Pinned Messages
      </h3>
      {pinnedMessages.map((message) => (
        <div 
          key={message.id} 
          className="text-sm cursor-pointer hover:bg-gray-200 p-2 rounded flex items-start transition-colors duration-150"
          onClick={() => handlePinnedMessageClick(message.id)}
        >
          <FaCommentAlt className="mr-2 mt-1 text-gray-500 flex-shrink-0" />
          <span className="text-gray-800">{message.text}</span>
        </div>
      ))}
    </div>
  );
};

const CustomChannelInner: React.FC = () => (
  <div className="flex flex-col overflow-y-auto">
    <PinnedMessageList />
    <div className="flex-grow">
      <MessageList />
    </div>
    <div className="sticky bottom-0 bg-white border-t border-gray-200 z-10">
      <MessageInput />
    </div>
  </div>
);

const UnreadCountUpdater: React.FC<{ channelId: string; onUnreadCountChange: (count: number) => void }> = ({ channelId, onUnreadCountChange }) => {
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const handleEvent = () => {
      const newUnreadCount = channel.countUnread();
      onUnreadCountChange(newUnreadCount);
    };

    handleEvent(); // Initial count

    channel.on('message.new', handleEvent);
    channel.on('message.read', handleEvent);

    return () => {
      channel.off('message.new', handleEvent);
      channel.off('message.read', handleEvent);
    };
  }, [channel, channelId, onUnreadCountChange]);

  return null;
};

const ChannelHeader: React.FC<{ userName: string; isOnline: boolean }> = ({ userName, isOnline }) => (
  <div className="flex items-center p-4 border-b">
    <FaUserTie className="w-6 h-6 mr-2" />
    <span className="font-medium">{userName}</span>
    <FaCircle className={`w-2 h-2 ml-2 ${isOnline ? 'text-green-500' : 'text-gray-400'}`} />
    <span className={`ml-1 text-xs ${isOnline ? 'text-green-500' : 'text-gray-400'}`}>
      {isOnline ? 'Online' : 'Offline'}
    </span>
  </div>
);

const StreamChatAccountant: React.FC = () => {
  const [client, setClient] = useState<StreamChat | null>(null);
  const [channel, setChannel] = useState<any | null>(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [notificationSound, setNotificationSound] = useState<HTMLAudioElement | null>(null);
  const [userOnlineStatus, setUserOnlineStatus] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  const [userID, setUserID] = useState<string>('');
const disconnectTimeoutRef = useRef<NodeJS.Timeout>();


  const [isConnected, setIsConnected] = useState(false);

  // Add refs to track connection state
  const isConnectedRef = useRef(false);
  const isDisconnectingRef = useRef(false);

  const handleUnreadCountChange = useCallback((count: number) => {
    setUnreadCount(count);
  }, []);


  // Add this comprehensive user status management function
const handleUserStatusChange = async (streamClient: StreamChat, isOnline: boolean) => {
  if (!streamClient || !userID) return;

  try {
    // Query the user's current status first
    const response = await streamClient.queryUsers({ id: userID });
    const currentUser = response.users[0];

    if (currentUser) {
      // Update the user's status
      await streamClient.upsertUser({
        id: userID,
        online: isOnline,
        invisible: !isOnline
      });

      // Update local state
      setUserOnlineStatus(isOnline);
    }
  } catch (error) {
    console.error('Error updating user status:', error);
  }
};


 // Replace the existing handleTabClose with this improved version
const handleTabClose = useCallback(async (streamClient: StreamChat) => {
  if (!streamClient || !streamClient.user || isDisconnectingRef.current) return;

  try {
    isDisconnectingRef.current = true;

    // Update user status before disconnecting
    await handleUserStatusChange(streamClient, false);

    // Only disconnect if currently connected
    if (isConnectedRef.current) {
      await streamClient.disconnectUser();
      isConnectedRef.current = false;
    }
  } catch (error) {
    console.error('Error handling tab close:', error);
  } finally {
    isDisconnectingRef.current = false;
  }
}, []);

  useEffect(() => {
    // Initialize notification sound
    const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/notification.mp3`);
    setNotificationSound(audio);
    return () => {
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, []);

  useEffect(() => {
    mixpanel.track('Accountant Chat View');
    const requestNotificationPermission = async () => {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    };
    requestNotificationPermission();
  }, []);

   // Modify the visibility change handler
const handleVisibilityChange = useCallback(async () => {
  if (!client) return;

  // Clear any existing timeout
  if (disconnectTimeoutRef.current) {
    clearTimeout(disconnectTimeoutRef.current);
  }

  if (document.visibilityState === 'hidden') {
    // Set a timeout to handle actual tab closing vs switching
    disconnectTimeoutRef.current = setTimeout(() => {
      handleTabClose(client);
    }, 1000);
  } else {
    if (!isConnectedRef.current && !isDisconnectingRef.current) {
      try {
        await client.connectUser(
          {
            id: client.userID!,
            invisible: false,
            online: true
          },
          client._getToken()!
        );
        isConnectedRef.current = true;
        
        // Update user status after reconnecting
        await handleUserStatusChange(client, true);
      } catch (error) {
        console.error('Error reconnecting:', error);
      }
    }
  }
}, [client, handleTabClose]);

// Add this effect to initialize user status monitoring
useEffect(() => {
  if (!client || !id) return;

  setUserID(id); // Store the user ID

  const handleUserPresence = async (event: any) => {
    if (event.user?.id === id) {
      setUserOnlineStatus(event.user.online || false);
      console.log('User presence changed:', {
        userId: event.user.id,
        online: event.user.online,
        lastActive: event.user.last_active
      });
    }
  };

  const monitorUserPresence = async () => {
    try {
      // Watch the user's presence with explicit presence flag
      await client.queryUsers(
        { id },
        { last_active: -1 },
        { presence: true }
      );

      // Get initial status
      const response = await client.queryUsers({ id });
      if (response.users.length > 0) {
        setUserOnlineStatus(response.users[0].online || false);
      }
    } catch (error) {
      console.error('Error monitoring user presence:', error);
    }
  };

  monitorUserPresence();
  
  // Listen for presence changes
  client.on('user.presence.changed', handleUserPresence);

  return () => {
    client.off('user.presence.changed', handleUserPresence);
  };
}, [client, id]);


    

    // Modified connection effect
  useEffect(() => {
    if (!client) return;

    const handleConnectionChange = ({ online = false }) => {
      isConnectedRef.current = online;
    };

    // Setup event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', () => handleTabClose(client));
    client.on('connection.changed', handleConnectionChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', () => handleTabClose(client));
      client.off('connection.changed', handleConnectionChange);
      
      // Cleanup when component unmounts
      handleTabClose(client);
    };
  }, [client, handleVisibilityChange, handleTabClose]);

  // Update user status monitoring
  useEffect(() => {
    if (!client || !id) return;

    const handleUserPresence = (event: any) => {
      if (event.user?.id === id) {
        setUserOnlineStatus(event.user.online || false);
        
        // Log presence changes for debugging
        console.log('User presence changed:', {
          userId: event.user.id,
          online: event.user.online,
          lastActive: event.user.last_active
        });
      }
    };

    const monitorUserPresence = async () => {
      try {
        // Watch the user's presence
        await client.queryUsers(
          { id },
          { last_active: -1 },
          { presence: true }
        );

        // Get initial status
        const response = await client.queryUsers({ id });
        if (response.users.length > 0) {
          setUserOnlineStatus(response.users[0].online || false);
        }
      } catch (error) {
        console.error('Error monitoring user presence:', error);
      }
    };

    monitorUserPresence();
    client.on('user.presence.changed', handleUserPresence);

    return () => {
      client.off('user.presence.changed', handleUserPresence);
    };
  }, [client, id]);

  useEffect(() => {
    let initialized = false;
    console.log('Starting accountant chat initialization...');

    const initChat = async () => {
      if (initialized) {
        console.log('Chat already initialized, skipping...');
        return;
      }
      initialized = true;

      try {
        const accountantId = localStorage.getItem('accId');
        console.log('Retrieved accountantId from localStorage:', accountantId);

        if (!accountantId || !id) {
          throw new Error('Missing required IDs');
        }

        console.log('Fetching chat token...');
        const response = await api.post<ChatData>(`${getApiBaseUrl()}/accountant-stream-chat-token/`, {
          accountant_id: accountantId,
          user_id: id
        });
        console.log('Full Token API response:', JSON.stringify(response.data, null, 2));

        const { 
          token, 
          api_key, 
          accountant_id, 
          accountant_name, 
          user_id, 
          user_name, 
          channel_id 
        } = response.data;

        setUserName(user_name);

        const chatClient = StreamChat.getInstance(api_key);
        console.log('Created Stream Chat instance');



        // Enhanced WebSocket connection logging
        chatClient.on('connection.changed', event => {
          console.log('WS Connection state changed:', {
            event,
            wsConnection: chatClient.wsConnection,
            wsConnectionStatus: chatClient.wsConnection?.ws?.readyState,
            wsURL: chatClient.wsConnection?.ws?.url,
            isConnecting: chatClient.wsConnection?.isConnecting,
            lastEvent: chatClient.wsConnection?.lastEvent,
            // tokenProvider: chatClient.tokenProvider ? 'exists' : 'null'
          });
        });

        chatClient.on('ws.error', error => {
          console.error('WebSocket Error:', {
            error,
            rawError: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            wsState: chatClient.wsConnection?.ws?.readyState,
            wsURL: chatClient.wsConnection?.ws?.url,
            lastEvent: chatClient.wsConnection?.lastEvent
          });
        });

        // Log all WebSocket messages for debugging
        chatClient.on('ws.message', msgEvent => {
          console.log('WebSocket Message:', {
            type: msgEvent.type,
            data: msgEvent.data,
            raw: JSON.stringify(msgEvent, null, 2)
          });
        });

        try {
          console.log('Attempting to connect accountant:', {
            accountant: {
              id: accountant_id,
              name: accountant_name,
            },
            wsConfig: {
              wsBaseURL: chatClient.wsBaseURL,
              wsConnection: chatClient.wsConnection ? 'exists' : 'null',
              tokenLength: token.length
            }
          });
            console.log("Conencting")
          // await chatClient.connectUser(
          //   {
          //     id: accountant_id,
          //     name: accountant_name,
          //   },
          //   token
          // );

          await chatClient.connectUser(
            {
              id: response.data.accountant_id,
              name: response.data.accountant_name,
            },
            response.data.token
          );
          
          isConnectedRef.current = true;

          console.log('Accountant connected successfully:', {
            wsState: chatClient.wsConnection?.ws?.readyState,
            userID: chatClient.userID,
            wsURL: chatClient.wsConnection?.ws?.url
          });

          // Create or update regular user in Stream
          const regularUserData = {
            id: user_id,
            name: user_name,
            role: 'user',
          };
          console.log('Creating/updating regular user with data:', regularUserData);
          await chatClient.upsertUsers([regularUserData]);

          // Small delay to ensure users are created
          await new Promise(resolve => setTimeout(resolve, 1000));

          console.log('Creating/getting channel with ID:', channel_id);
          const chatChannel = chatClient.channel('messaging', response.data.channel_id, {
            members: [response.data.accountant_id, response.data.user_id],
            name: `Chat with ${response.data.user_name}`,
          });

          try {
            await chatChannel.watch();
            console.log('Channel watch successful:', {
              channelId: chatChannel.id,
              channelType: chatChannel.type,
              memberCount: chatChannel.state.members ? Object.keys(chatChannel.state.members).length : 0,
              wsState: chatClient.wsConnection?.ws?.readyState
            });

            // Explicitly add members to channel
            const memberData = [
              { user_id: user_id, role: 'member' },
              { user_id: accountant_id, role: 'channel_member' }
            ];
            console.log('Adding members to channel:', memberData);
            await chatChannel.addMembers(memberData);

            // Watch user online status
            const userStatus = await chatClient.queryUsers({ id: user_id });
            console.log('User Status:', {
              status: userStatus.users[0]?.online,
              lastActive: userStatus.users[0]?.last_active
            });
            setUserOnlineStatus(userStatus.users[0]?.online || false);

            setUserName(response.data.user_name);
            setChannel(chatChannel);
            setClient(chatClient);

          } catch (watchError: any) {
            console.error('Channel Watch Error:', {
              error: watchError,
              message: watchError.message,
              channelId: chatChannel.id,
              wsState: chatClient.wsConnection?.ws?.readyState,
              raw: JSON.stringify(watchError, Object.getOwnPropertyNames(watchError))
            });
            throw watchError;
          }

        } catch (error: any) {
          console.error('Stream Chat Connection Error:', {
            error: error,
            message: error.message,
            code: error.code,
            response: error.response,
            wsError: error.isWSFailure ? {
              lastEvent: chatClient.wsConnection?.lastEvent,
              wsState: chatClient.wsConnection?.ws?.readyState,
              wsURL: chatClient.wsConnection?.ws?.url,
              isConnecting: chatClient.wsConnection?.isConnecting,
            } : 'Not a WS Error',
            stack: error.stack,
            raw: JSON.stringify(error, Object.getOwnPropertyNames(error))
          });
          throw error;
        }

      } catch (error: any) {
        console.error('Chat Initialization Error:', {
          error: error,
          message: error.message,
          code: error.code,
          status: error.status,
          isWSFailure: error.isWSFailure,
          response: error.response,
          stack: error.stack,
          raw: JSON.stringify(error, Object.getOwnPropertyNames(error))
        });
        setError(error.message || 'Failed to initialize chat');
      }
    };

    if (id) {
      initChat();
    }

    return () => {
      if (client && isConnectedRef.current) {
        handleTabClose(client);
      }
    };
  }, [id, handleTabClose]);

  useEffect(() => {
    if (!client || !client.user) return;

    const handleNewMessage = async (event: Event) => {
      console.log('New message event:', event);
      if (event.type === 'message.new' && event.user?.id !== client.user?.id) {
        if (notificationSound) {
          notificationSound.play().catch(error => console.error('Error playing sound:', error));
        }

        if (Notification.permission === 'granted') {
          new Notification('New Message', {
            body: event.message?.text || 'You have a new message',
            icon: '/path/to/notification-icon.png'
          });
        }
      }
    };

    client.on('message.new', handleNewMessage);
    return () => {
      client.off('message.new', handleNewMessage);
    };
  }, [client, notificationSound]);

  if (error) {
    return <div className="p-4 text-red-600">Error: {error}</div>;
  }

  if (!client || !channel) {
    return <div className="flex items-center justify-center z-50 h-screen bg-white">
    <div className="flex flex-col items-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
    </div>
  </div>;
  }

  return (
    <div className="flex flex-col h-screen">
      <ChannelHeader userName={userName} isOnline={userOnlineStatus} />
      <div className="flex-grow overflow-y-auto">
        <Chat client={client} theme="str-chat__theme-light">
          <Channel channel={channel} ReactionSelector={CustomReactionSelector} ReactionsList={CustomReactionsList}>
            <UnreadCountUpdater
              channelId={channel.id}
              onUnreadCountChange={handleUnreadCountChange}
            />
            <Window>
              <CustomChannelInner />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
  );
  };

export default StreamChatAccountant;