import React, { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaStripe } from 'react-icons/fa';
import { getApiBaseUrl, getServerUrl } from '../../../config/constants';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

interface BillingDetails {
  stripe_customer_id: string;
  payment_id: string;
  trial_end_date: string;
  next_billing_date: string;
  invoices: {
    id: number;
    billing_type: string;
    fixed_amount: number | null;
    percentage: number | null;
    minimum_amount: number | null;
    total_amount: number;
    currency: string;
    created_at: string;
    updated_at: string;
    status: string;
  }[];
}

const InvoiceView: React.FC = () => {
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState(false)
  const [error, setError] = useState<string | null>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const fetchBillingDetails = async () => {
      const userId = localStorage.getItem('userId');
      if (userId) {
        setPageLoading(true)
        try {
          const response = await fetch(`${getApiBaseUrl()}/customer-billing/${userId}/`);
          const data = await response.json();
          if (data.success) {
            setBillingDetails(data.billing_details);
            setPageLoading(false)
          }
        } catch (error) {
          console.error('Error fetching billing details:', error);
          setPageLoading(false)
        }
      }
    };

    fetchBillingDetails();
  }, []);

  const handleUpdatePaymentMethod = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        throw new Error('User ID not found');
      }
      
      const response = await axios.get<{ url: string }>(`${getApiBaseUrl()}/create-customer-portal-session`, {
        params: { user_id: userId }
      });
      
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('No URL returned from the server');
      }
    } catch (err) {
      console.error('Error creating customer portal session:', err);
      
      if (axios.isAxiosError(err)) {
        const axiosError = err;
        setError(axiosError.response?.data?.error || axiosError.message);
      } else {
        setError((err as Error).message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (pageLoading) return <div className="flex items-center h-screen justify-center z-50 bg-white">
    <div className="flex flex-col items-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
    </div>
  </div>;

  return (
    <div className="mt-2 max-w-4xl">
      <h3 className={`text-xl font-semibold mb-6 ${isMobile && `text-center`}`}>Billings</h3>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="bg-white rounded-md border p-6">
          <div className="mb-8">
            <p className="text-gray-600 font-semibold">Payment Details</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600">Card Number</p>
              <p>**** **** **** 1234</p>
            </div>
            <div>
              <p className="text-gray-600">Expiration Date</p>
              <p>12/2024</p>
            </div>
            {billingDetails && (
              <div>
                <p className="text-gray-600">Trial End Date</p>
                <p>{billingDetails.trial_end_date}</p>
              </div>
            )}
            {billingDetails && (
              <div>
                <p className="text-gray-600">Next Billing Date</p>
                <p>{billingDetails.next_billing_date}</p>
              </div>
            )}
          </div>
          <div className="mt-6">
            <button
              onClick={handleUpdatePaymentMethod}
              disabled={isLoading}
              className={`inline-block bg-primary hover:bg-primary text-white font-semibold py-2 px-4 rounded-lg ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? 'Loading...' : 'Update Payment Method'} <FaExternalLinkAlt className="inline-block ml-2" />
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
          <div className="mt-2 space-x-2 flex items-center">
            <p className="text-gray-600">Powered by</p>
            <FaStripe className="text-4xl text-blue-500" size={50} />
          </div>
        </div>
        <div className="bg-white border rounded-md p-6">
          <div className="mb-8">
            <p className="text-gray-600 font-semibold">Invoices</p>
          </div>
          <div className="divide-y divide-gray-200">
            {billingDetails?.invoices.length === 0 && (
              <div className="text-center py-4">
                <p>No invoices found.</p>
              </div>
            )}
            {billingDetails?.invoices.map((invoice) => (
              <div key={invoice.id} className="py-2">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-gray-600">Invoice ID</p>
                    <p>{invoice.id}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Billing Type</p>
                    <p>{invoice.billing_type}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Amount</p>
                    <p>${invoice.total_amount}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Currency</p>
                    <p>{invoice.currency}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Created At</p>
                    <p>{new Date(invoice.created_at).toLocaleDateString()}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Status</p>
                    <div
                      className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                        invoice.status === 'paid'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {invoice.status === 'paid' ? (
                        <>
                          <svg
                            className="mr-1.5 h-2 w-2 text-green-400"
                            fill="currentColor"
                            viewBox="0 0 8 8"
                          >
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          <p className="text-green-800">Paid</p>
                        </>
                      ) : (
                        <>
                          <svg
                            className="mr-1.5 h-2 w-2 text-yellow-400"
                            fill="currentColor"
                            viewBox="0 0 8 8"
                          >
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          <p className="text-yellow-800">Pending</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;