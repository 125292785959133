import React, { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { SummaryProvider, useSummaryContext } from "../../../services/summary";
import BookkeepingStatus from "./BookkeepingStatus";
import ProfitGraph from "./ProfitGraph";
import ProfitLoss from "./ProfitLoss";
import TopExpensesCard from "./TopExpenseCard";
import axios from "axios";
import { GoBellFill } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { RiGiftLine } from "react-icons/ri";

import NotificationList from "../../../../components/NotificationList";
import { getApiBaseUrl } from "../../../../config/constants";
import MessageCard from "../../../../components/MessageCard";
import { getBusinessDetailsByUserId } from "../../../../pages/OnBoarding/hooks";

import { FiInfo } from "react-icons/fi";
import { UserProgressType } from "../elements/types";
import Popup from "../elements/Popup";
import ProgressBar from "../elements/ProgressBar";
import mixpanel from "mixpanel-browser";

const DashboardSummaryContent: React.FC = () => {
  const data = useSummaryContext();

  const [isOpen, setIsOpen] = useState(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [userProgressData, setUserProgressData] = useState([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [billingStatus, setBillingStatus] = useState<'enabled' | 'disabled' | 'no-billing' | null>(null);
  const [hasBusinessDetails, setHasBusinessDetails] = useState<boolean | null>(null);
  const [profileData, setProfileData] = useState<UserProgressType | null>();
  const [ispopopen, setIspopopen] = useState<boolean>(false);
  const [progress, setProgress] = useState<number[]>([]);

  const valid = profileData?.business_details_added && profileData?.trial_activated && profileData?.financial_summary_reviewed && profileData?.accountant_messages_checked === true;

  useEffect(() => {
    const progress = [1,2];
    if (profileData?.trial_activated) progress.push(3);
    if (profileData?.business_details_added) progress.push(4);
    if (profileData?.financial_summary_reviewed) progress.push(5);
    if (profileData?.accountant_messages_checked) progress.push(6);
    setProgress(progress);
  }, [profileData]);

  const openPopup = () => {
    setIspopopen(true);
  } 

  const closePopup = () => {
    setIspopopen(false);
  }

  const checkBillingStatus = async () => {
    const userId = localStorage.getItem("userId") || "1";
    try {
      const response = await axios.get(`${getApiBaseUrl()}/customer-billing/${userId}/`);
      if (response.data.success) {
        setBillingStatus('enabled');
      } else if (response.data.error === "no billing found") {
        setBillingStatus('no-billing');
      } else {
        setBillingStatus('disabled');
      }
    } catch (error) {
      console.error('Error fetching customer billing data:', error);
      setBillingStatus('disabled');
    }
  };

  const fetchNotifications = async () => {
    try {
      const userId = localStorage.getItem("userId") || "1";
      const response = await axios.get<Notification[]>(
        `${getApiBaseUrl()}/notification/?user=${userId}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const checkBusinessDetails = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const businessRes = await getBusinessDetailsByUserId(userId);
        setHasBusinessDetails(businessRes.success);
      } catch (error) {
        console.error('Error fetching business details:', error);
        setHasBusinessDetails(false);
      }
    }
  };

  const userProgress = () => {
    const userId = localStorage.getItem("userId") || "1";
    axios.get(`${getApiBaseUrl()}/user-progress/?user=${userId}`)
      .then((response) => {
        console.log(response.data);
        setUserProgressData(response.data);
        if (response.data.length > 0) {
          console.log("User Progress:", response.data[0]);
          setProfileData(response.data[0]);
          if (!response.data[0].accountant_messages_checked) {
            setNotificationData({
              id: 1,
              message: "Your account is yet to be activated!!",
              type: "normal",
              link: "",
            });
          }
          if (!response.data[0].business_details_added) {
            setNotificationData({
              id: 1,
              message: "Fill Your Business Details to get started",
              type: "normal",
              link: "/dashboard/myaccount",
            });
          }
          if (!response.data[0].email_verified) {
            setNotificationData({
              id: 1,
              message: "Verify your email to get started",
              type: "normal",
              link: "",
            });
          }
        }
      });
  };

  useEffect(() => {
    mixpanel.track('Dashboard Visited');
    fetchNotifications();
    userProgress();
    checkBusinessDetails();
    checkBillingStatus();
  }, []);

  return (
    <div className="w-full">
      {!profileData && <div className="h-screen flex items-center justify-center z-50 bg-white">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
          <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
        </div>
      </div>}

      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14">
          Dashboard
        </h1>

        <div className="flex items-center ml-0 lg:ml-auto">
          <button
            className="flex items-center text-black relative"
            onClick={toggleDrawer}
          >
            <FaBell className="text-2xl text-gray-700" />
            {notifications && notifications.length > 0 && (
              <span className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full px-1.5 py-0.5 text-xs">
                {notifications.length}
              </span>
            )}
          </button>
        </div>
      </div>
      <div className="pl-0 md:pl-6">
        <div
          className={`fixed top-0 right-0 lg:w-1/4 md:w-full sm:w-full h-full bg-gray-50 shadow-sm transition-transform duration-300 ease-in-out transform z-20 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="p-4 bg-gray-900 ">
            <div className="flex  justify-between items-center w-full p-4 border-b mb-2 gap-4 ">
              <button className="text-black" onClick={toggleDrawer}>
                <GrClose className="text-xl" color="white" />
              </button>

              <h2 className="text-xl font-bold text-white">Notification </h2>
              <GoBellFill size={24} color="white" />
            </div>
            <div className="h-screen w-full bg-red dark">
              <NotificationList />
            </div>
          </div>
        </div>
        <div className="pt-24">
          {billingStatus === 'no-billing' && (
            <MessageCard 
              message="Activate your 1-month free trial of Outss Accountant" 
              messageType="information" 
              link="/dashboard/billing" 
              icon={<RiGiftLine size={24} color="#69339C"/>} 
            />
          )}

          {!valid && (
            <ProgressBar 
              featuresExplored={progress.length} 
              trial_activated={profileData?.trial_activated}
              openPopup={openPopup}
            />
          )}

          <ProfitLoss />
          <div className="profit-graph">
            <ProfitGraph />
          </div>
          <div className="h-8"></div>

          <BookkeepingStatus />
          <div className="h-8"></div>
          {data && <TopExpensesCard data={data} />}

          <div className="h-8"></div>
        </div>
      </div>
      {ispopopen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75 w-full h-full overflow-auto">
          <Popup closePopup={closePopup} />
        </div>          
      )}
    </div>
  );
};

const DashboardSummary: React.FC = () => {
  return (
    <SummaryProvider>
      <DashboardSummaryContent />
    </SummaryProvider>
  );
};

export default DashboardSummary;