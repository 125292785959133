import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { ErrorBoundary } from 'react-error-boundary';
import { FaCalendar, FaDownload } from 'react-icons/fa';
import { getHumanReadableDate } from '../../utils';
import Loader from 'react-js-loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getApiBaseUrl } from '../../../config/constants';
import { useMediaQuery } from 'react-responsive';
import mixpanel from 'mixpanel-browser';
import api from '../../../services/api';
import { FormData } from  "../../../pages/OnBoarding/hooks";
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

interface BalanceSheetData {
  Header?: {
    Time: string;
    ReportName: string;
    ReportBasis: string;
    StartPeriod: string;
    EndPeriod: string;
    SummarizeColumnsBy: string;
    Currency: string;
    Option: {
      Name: string;
      Value: string;
    }[];
  };
  Columns?: {
    Column: {
      ColTitle: string;
      ColType: string;
      MetaData: {
        Name: string;
        Value: string;
      }[];
    }[];
  };
  Rows?: {
    Row: RowData[];
  };
  response?: string;
}

interface RowData {
  Header?: {
    ColData: {
      value: string;
      id?: string;
    }[];
  };
  Rows?: {
    Row: RowData[];
  };
  Summary?: {
    ColData: {
      value: string;
    }[];
  };
  ColData?: {
    value: string;
    id?: string;
  }[];
  type: string;
  group?: string;
}

type DayRange = {
  from: Date | undefined;
  to: Date | undefined;
};

const formatCurrency = (value: string | undefined) => {
  if (!value) return '';
  const numValue = parseFloat(value);
  const absValue = Math.abs(numValue);
  const formattedValue = absValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  
  return numValue < 0 ? `(${formattedValue})` : formattedValue;
};

const renderRows = (rows: RowData[], level = 0) => {
  return rows.map((row, rowIndex) => {
    if (row.Rows) {
      // This is a parent row, render only the header
      return (
        <React.Fragment key={rowIndex}>
          <tr className={`bg-gray-${level % 2 === 0 ? '50' : '100'} rounded-lg`}>
            <td className="px-4 py-3 text-sm md:text-base font-semibold" style={{ paddingLeft: `${level * 20}px` }}>
              {row.Header?.ColData[0].value}
            </td>
            <td className="px-4 py-3 text-sm text-gray-600 font-light md:text-base text-right">
              {''}
            </td>
          </tr>
          {renderRows(row.Rows.Row, level + 1)}
        </React.Fragment>
      );
    } else {
      // This is a leaf row, render the data
      return (
        <tr key={rowIndex} className={`${level % 2 === 0 ? 'bg-white' : 'bg-gray-50'} rounded-lg`}>
          <td className="px-4 py-3 text-sm md:text-base" style={{ paddingLeft: `${level * 20}px` }}>
            {row.ColData?.[0].value}
          </td>
          <td className="px-4 py-3 text-lg text-black font-bold md:text-base text-right">
            {formatCurrency(row.ColData?.[1]?.value)}
          </td>
        </tr>
      );
    }
  });
};

const BalanceSheet: React.FC = () => {
  const [data, setData] = useState<BalanceSheetData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DayRange>({
    from: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });
  const [busData, setBusData] = useState<FormData | null>(null);
  const [showDownload, setShowDownload] = useState(false);

  useEffect(() => {
    const hasSections = 
      data?.Rows?.Row.find((section:RowData) => 
        section.Rows?.Row.length === 2 || 
        section.ColData?.length === 2
      );
    
    setShowDownload(!!hasSections);
  }, [data]);
  
  useEffect(() => {
    const fetchBusData = async () => {
      try {
        const userId = localStorage.getItem("userId");
          const response = await axios.get(
            `${getApiBaseUrl()}/business-details/user/${userId}/`
          );
          setBusData(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setIsLoading(false);
      }
    };
    fetchBusData();
  }, []);

  const componentRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (dateRange: { startDate: Date | undefined; endDate: Date | undefined }) => {
    setSelectedDateRange({ from: dateRange.startDate, to: dateRange.endDate });
  };

  const openDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const closeDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fromDate = selectedDateRange.from?.toLocaleDateString('en-CA') || ""; 
        const toDate = selectedDateRange.to?.toLocaleDateString('en-CA') || "";
        const userId = localStorage.getItem('userId');
        const response = await api.get(
          `${getApiBaseUrl()}/balance-sheet/${userId}/?start_date=${fromDate}&end_date=${toDate}`
        );
        setData(response.data);
      } catch (error) {
        setError('Failed to fetch balance sheet data');
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedDateRange]);

  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // "March 31, 2024"
  };  

  const generateExcel = (balanceSheetData: BalanceSheetData, startDate: string, endDate: string) => {
    console.log('balanceSheetData',balanceSheetData)
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
  
    // Helper function to add a row with optional styling
    const addRow = (row: (string | number)[], styles: XLSX.CellStyle = {}) => {
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
      const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
      const rowIndex = range.e.r;
      for (let i = 0; i < row.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: i });
        if (ws[cellRef]) {
          ws[cellRef].s = styles;
        }
      }
    };
  
    // Styles
    const boldStyle: XLSX.CellStyle = { font: { bold: true } };
    const underlineStyle: XLSX.CellStyle = { font: { underline: true } };
    const boldUnderlineStyle: XLSX.CellStyle = { font: { bold: true, underline: true } };
  
    // Helper function to format number as positive or return "0.00" if empty
    const formatNumber = (value: string | number | undefined): string => {
      if (value === undefined || value === "" || isNaN(Number(value))) {
        return "0.00";
      }
      // Convert value to a number and format it with two decimal places
      const numValue = Number(value);
      return numValue.toFixed(2);
    };  
  
    // Title and date
    addRow([balanceSheetData.Header?.ReportName || "Balance Sheet"], boldUnderlineStyle);
    addRow([`As of ${formatDate(endDate)}`], boldStyle);
    addRow([]);  // Empty row
  
    // Helper function to recursively add rows
    const addRows = (rows: RowData[], level: number = 0) => {
      rows.forEach(row => {
        if (row.Header) {
          // This is a parent row
          addRow([row.Header.ColData[0].value, ""], { 
            font: { bold: true },
            fill: { fgColor: { rgb: "EEEEEE" } }
          });
        } else if (row.ColData) {
          // This is a leaf row
          addRow([
            "  ".repeat(level) + row.ColData[0].value,
            formatNumber(row.ColData[1]?.value)
          ]);
        }
  
        if (row.Rows) {
          addRows(row.Rows.Row, level + 1);
        }
  
        if (row.Summary) {
          addRow([
            "Total " + (row.Header?.ColData[0].value || ""),
            formatNumber(row.Summary.ColData[1]?.value)
          ], boldStyle);
        }
      });
    };
  
    // Add Assets
    if (balanceSheetData.Rows?.Row) {
      const assetsSection = balanceSheetData.Rows.Row.find(section => section.Header?.ColData[0].value === 'ASSETS');
      if (assetsSection) {
        console.log('assetsSection', assetsSection);        
        addRow(["Assets"], boldUnderlineStyle);
        addRows(assetsSection.Rows?.Row || []);
        addRow([]);  // Empty row
      }

      addRow(["TOTAL ASSETS", formatNumber(assetsSection?.Summary?.ColData[1]?.value)], boldStyle);
      addRow([]);
  
      // Add Liabilities and Equity
      const liabilitiesAndEquitySection = balanceSheetData.Rows.Row.find(section => section.Header?.ColData[0].value === 'LIABILITIES AND EQUITY');
      if (liabilitiesAndEquitySection) {    
        addRow(["Liabilities and Equity"], boldUnderlineStyle);
        addRows(liabilitiesAndEquitySection.Rows?.Row || []);
        addRow([])
        
        // Get Total Liabilities value
        const totalLiabilities = Number(
            liabilitiesAndEquitySection.Rows?.Row
                .find(row => row.Header?.ColData[0].value === 'Liabilities')
                ?.Summary?.ColData[1].value || 0
        );

        // Get Total Equity value
        const totalEquity = Number(
            liabilitiesAndEquitySection.Rows?.Row
                .find(row => row.Header?.ColData[0].value === 'Equity')
                ?.Summary?.ColData[1].value || 0
        );

        // Calculate Total Liabilities & Equity based on whether Equity is negative
        const totalLiabilitiesAndEquity = totalEquity < 0 
            ? totalLiabilities - Math.abs(totalEquity)  // If equity is negative, subtract its absolute value
            : totalLiabilities + totalEquity;           // If equity is positive or zero, add it

        // Add the calculated total row
        addRow([
            "TOTAL LIABILITIES & EQUITY",
            formatNumber(totalLiabilitiesAndEquity)
        ], boldStyle);
        
        addRow([]);
      }

      addRow([new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ', ' + new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })], boldStyle);
    }
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Balance Sheet");
  
    // Generate Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use file-saver to trigger download
    saveAs(blob, `balance_sheet_${startDate}_to_${endDate}.xlsx`);
  };

  useEffect(()=>{
    mixpanel.track('Balance Sheet Visited');
  },[])

  const calculateTotals = (data: BalanceSheetData) => {
    let totalAssets = 0;
    let totalLiabilitiesAndEquity = 0;

    if (data.Rows?.Row) {
      data.Rows.Row.forEach(section => {
        if (section.Header?.ColData[0].value === 'ASSETS') {
          totalAssets = parseFloat(section.Summary?.ColData[1]?.value || '0');
        } else if (section.Header?.ColData[0].value === 'LIABILITIES AND EQUITY') {
          totalLiabilitiesAndEquity = parseFloat(section.Summary?.ColData[1]?.value || '0');
        }
      });
    }

    return { totalAssets, totalLiabilitiesAndEquity };
  };



  const handleExcelDownload = () => {
    if (data) {
      const startDate = selectedDateRange.from?.toLocaleDateString('en-CA') || ""; 
      const endDate = selectedDateRange.to?.toLocaleDateString('en-CA') || "";
      generateExcel(data, startDate, endDate);
    }
  };

  const handleDownloadPDF = async () => {
    if (componentRef.current) {
      mixpanel.track('Balance Sheet Download PDF Visited');
      const originalWidth = componentRef.current.style.width;
      componentRef.current.style.width = '1200px';

      const canvas = await html2canvas(componentRef.current, {
        scale: 1,
        useCORS: true,
        logging: true,
        width: 1200,
        height: componentRef.current.scrollHeight,
      });

      componentRef.current.style.width = originalWidth;

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width ;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(`${busData?.businessName} balance-sheet.pdf`);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-center">
        <Loader type="bubble-top" bgColor={"#69339C"} color={"#69339C"} title={"knocking your accounts..."} size={20} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  const { totalAssets, totalLiabilitiesAndEquity } = calculateTotals(data);

  const renderBalanceSheet = (data: BalanceSheetData) => {
    let totalAssets = 0;
    let assetsSection = null;
    let totalLiabilitiesAndEquity = 0;
    let liabilitiesAndEquitySection = null;

    if (data.Rows?.Row) {
      data.Rows.Row.forEach(section => {
        if (section.Header?.ColData[0].value === 'ASSETS') {
          assetsSection = section;
          totalAssets = parseFloat(section.Summary?.ColData[1]?.value || '0');
        } else if (section.Header?.ColData[0].value === 'LIABILITIES AND EQUITY') {
          liabilitiesAndEquitySection = section;
          totalLiabilitiesAndEquity = parseFloat(section.Summary?.ColData[1]?.value || '0');
        }
      });
    }

    return (
      <>
        {assetsSection && (
          <>
            {renderRows([assetsSection])}
            <tr className="bg-gray-200 font-bold">
              <td className="px-4 py-3 text-sm md:text-base">Total Assets</td>
              <td className="px-4 py-3 text-md md:text-base text-right">
                {formatCurrency(totalAssets.toString())}
              </td>
            </tr>
          </>
        )}
        {liabilitiesAndEquitySection && (
          <>
            {renderRows([liabilitiesAndEquitySection])}
            <tr className="bg-gray-200 font-bold">
              <td className="px-4 py-3 text-sm md:text-base">Total Liabilities & Equity</td>
              <td className="px-4 py-3 text-md md:text-base text-right">
                {formatCurrency(totalLiabilitiesAndEquity.toString())}
              </td>
            </tr>
          </>
        )}
      </>
    );
  };
  console.log(data.Header?.ReportName || 'Balance Sheet');
  

  return (
    <div className='p-0 md:p-6'>
      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex md:hidden flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14 text-primary">
          {data.Header?.ReportName || 'Balance Sheet'}
        </h1>
        {/* <div className="flex items-center ml-0 lg:ml-auto">
          {data.Header &&<button
            onClick={handleDownloadPDF}
            className="bg-primary text-white text-sm py-2 px-4 rounded-lg hover:shadow-md flex items-center"
          >
            <FaDownload className="mr-2" />
            Download PDF
          </button>}
        </div> */}
      </div>
    
      <div className="hidden md:flex flex-col md:flex-row justify-between mb-8 place-items-start">
        <h1 className="text-2xl md:text-3xl font-bold mb-4 text-primary">{data.Header?.ReportName || 'Balance Sheet'}</h1>
        <div className="flex flex-row space-x-2 md:space-x-4 place-items-center justify-center">
         {showDownload && <button className="bg-white text-primary border text-sm py-2 px-4 rounded-lg hover:shadow-md shadow-md flex items-center" onClick={handleExcelDownload}>
            <FaDownload className="mr-2" />
            Download Report (Excel)
          </button>}
            <button
              onClick={openDatePicker}
              className="flex flex-row space-x md:space-x-2 place-items-center justify-center bg-primary text-white text-sm py-2 px-4 rounded-lg hover:bg-indigo-700 group shadow-md"
            >
              <FaCalendar className="text-lg md:text-xl text-white" />
              <p className="text-white text-sm md:text-base">
                {getHumanReadableDate(selectedDateRange.from || new Date())}
              </p>
              <p className="text-gray-200 text-sm md:text-base">to</p>
              <p className="text-white text-sm md:text-base">
                {getHumanReadableDate(selectedDateRange.to || new Date())}
              </p>
            </button>
          
        </div>
      </div>

      <div className="md:hidden flex flex-row px-6 space-x-10 md:space-x-4 pt-24 place-items-center justify-center">
        <button
          onClick={openDatePicker}
          className="flex flex-row space-x md:space-x-2 place-items-center justify-between w-full bg-primary text-white text-sm py-2 px-4 rounded-lg hover:bg-indigo-700 group shadow-md"
        >
          <FaCalendar className="text-2xl md:text-xl text-white" />
          <p className="text-white text-xl md:text-base">
            {getHumanReadableDate(selectedDateRange.from || new Date())}
          </p>
          <p className="text-gray-200 text-xl md:text-base">to</p>
          <p className="text-white text-xl md:text-base">
            {getHumanReadableDate(selectedDateRange.to || new Date())}
          </p>
        </button>
      </div>

      {isDatePickerOpen && (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div>
              <h2>Something went wrong</h2>
              <button onClick={resetErrorBoundary}>Try again</button>
            </div>
          )}
        >
          <CustomDatePicker currentDateRange={{
              startDate: selectedDateRange.from,
              endDate: selectedDateRange.to
            }} onClose={closeDatePicker} onDateChange={handleDateChange} from='Balance Sheet' 
          />
        </ErrorBoundary>
      )}

      <div className="w-full flex-col items-center bg-white p-6">
        <div className="overflow-x-auto rounded-xl border p-4 md:mt-0" ref={componentRef}>
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr className="bg-white text-black rounded-lg">
                <th className="px-4 py-3 text-sm md:text-base text-left w-3/4">Account</th>
                <th className="px-4 py-3 text-sm md:text-base text-right w-1/4">Balance</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data && renderBalanceSheet(data)}
            </tbody>
          </table>
        </div>

        {data && data.Rows?.Row && (
          <div className="mt-4 text-sm">
            <strong>Report Basis:</strong> {data.Header?.ReportBasis}<br />
            <strong>Currency:</strong> {data.Header?.Currency}<br />
            <strong>Generated on:</strong> {new Date(data.Header?.Time || '').toLocaleString()}
          </div>
        )}

        {data && data.Rows?.Row && (
          <>
            {parseFloat(data.Rows.Row[0].Summary?.ColData[1]?.value || '0') !== 
             parseFloat(data.Rows.Row[1].Summary?.ColData[1]?.value || '0') && (
              <div className="mt-4 text-red-500">
                Warning: Total Assets do not match Total Liabilities and Equity
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BalanceSheet;