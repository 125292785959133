import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrialActivation from '../Payments/TrialActivation';
import InvoiceView from '../Invoicing/InvoiceView';
import { getApiBaseUrl } from '../../../config/constants';
import LoadingScreen from '../../../components/LoadingScreen';



const Billings: React.FC = () => {
  const [billingEnabled, setBillingEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userid = localStorage.getItem("userId")
    setIsLoading(true);
    axios.get(`${getApiBaseUrl()}/customer-billing/${userid}/`)
      .then(response => {
        setBillingEnabled(response.data.success);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching customer billing data:', error);
        setBillingEnabled(false); // Assume failure on error
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto p-4">
      {isLoading ? <div className="flex items-center h-screen justify-center z-50 bg-white">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
            <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
          </div>
        </div> :

        billingEnabled ? (
          <InvoiceView /> 
        ) : (
          <TrialActivation /> 
        )
      }
    </div>
  );
}

export default Billings;
