import React from "react";
import { forgotPassword } from "./hooks";
import logo from "../../assets/logo.png";
import { FaCircleCheck } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string>("");
  const [success, setSuccess] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const accountant = useLocation().pathname.includes('accountant') 
  console.log('accountant', accountant);  

  const handleSubmit = (e: React.FormEvent) => {
    if (isLoading) return;
    e.preventDefault();
    if (email === "") {
      setError("Please enter your email");
      return;
    }
    setIsLoading(true);
    forgotPassword({ email, type: accountant? 'accountant' : 'user' })
      .then((response) => {
        console.log("API call success:", response);
        if (response.success) {
          setIsSuccess(true);
          setSuccess("Please check your email to reset the password");
          setError("");
        } else {
          setError(response.response || "An error occurred");
          setSuccess("");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed:", error);
        setError("An error occurred");
        setSuccess("");
        setIsLoading(false);
      });
  };

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Outss Logo" />
        <h2 className="mt-6 text-center text-xl leading-9 font-extrabold text-gray-900">
          Forgot Password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onClick={() => setError("")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>

            {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
            <div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm leading-5">
                <a
                  href={accountant ? '/accountant/login' :'/signin'}
                  className="font-medium text-primary hover:text-primary focus:outline-none focus:underline"
                >
                  Back to Login
                </a>
              </div>
            </div>
          </form>
          {isSuccess && 
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75" onClick={()=>setIsSuccess(false)}>
              <div className="bg-white p-4 rounded shadow-lg h-52 align-middle flex sm:rounded-lg">
                <div className="flex items-center justify-center flex-col mb-4 gap-2">
                  <FaCircleCheck className="text-green-500 text-7xl mb-2"/>
                  <div className="text-green-500 text-sm">{success}</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;